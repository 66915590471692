<template>
  <nav class="pagination" v-if="lastPage && lastPage !== 1">
    <div class="pagination-counter" v-if="lastPage !== 1">
      <span class="pagination-counter-from">{{ paginationItemFirst }}</span> -
      <span class="pagination-counter-to">{{ paginationItemLast }}</span> sur
      <span class="pagination-counter-total">{{ total }}</span>
    </div>
    <div class="pagination-links">
      <Btn
        class="pagination-links-prev"
        round
        grow
        icon="chevron-big-left"
        :disabled="page === 1"
        @click="navigateTo('previous')"
      />
      <Btn
        class="pagination-links-next"
        round
        grow
        icon="chevron-big-right"
        :disabled="page === lastPage"
        @click="navigateTo('next')"
      />
    </div>
  </nav>
</template>

<script>
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'Pagination',

  components: {
    Btn,
  },

  props: {
    from: {
      type: String,
    },
    to: {
      type: String,
    },
  },

  data() {
    return {
      page: '',
      count: '',
      total: '',
      lastPage: '',
      limit: '',
      meta: {},
      forceRender: false,
    }
  },

  computed: {
    paginationItemFirst() {
      if (this.page !== 1) {
        if (this.page !== this.lastPage) {
          return this.limit * this.page - this.count + 1
        }
        return this.total - this.count + 1
      }

      return 1
    },

    paginationItemLast() {
      return this.page !== this.lastPage ? this.page * this.limit : this.total
    },
  },
  updated() {
    this.updatePagination()
  },
  mounted() {
    this.emitter.on('list-send-meta', this.setMeta)
  },
  methods: {
    setMeta(meta) {
      this.meta = meta
      this.updatePagination()
    },
    updatePagination() {
      this.page = Number(this.$route.query.page ?? this.meta.pagination.page)
      this.count = this.meta.pagination.count
      this.total = this.meta.pagination.count_over
      this.lastPage = this.meta.pagination.last
      this.limit = this.meta.pagination.limit
    },
    onChangePage() {
      const params = {
        limit: this.limit,
        page: this.page,
      }

      this.emitter.emit('list-update-params', params)
    },

    navigateTo(type) {
      switch (type) {
        case 'next':
          this.page += 1
          break
        case 'previous':
          this.page -= 1
          break
        default:
          this.page = 1
      }

      this.$router.replace({
        query: { ...this.$route.query, page: this.page },
      })
      this.$emit('change-page', this.page)

      this.onChangePage()
    },
  },
}
</script>

<style lang="scss" scoped>
/* PAGINATION */

.pagination {
  display: flex;
  align-items: center;

  .view-header__header & {
    margin-right: $gutter-half;
  }
}

.pagination-links {
  margin-left: $gutter-half;
}

.pagination-counter {
  font-weight: $font-weight-bold;
  color: $color-gray;
}

.pagination-counter-from,
.pagination-counter-to,
.pagination-counter-total {
  color: $body-color-base;
}
</style>
